export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [];

export const dictionary = {
		"/": [7],
		"/admin": [8,[2]],
		"/admin/assessments": [9,[2]],
		"/admin/classes/[id]": [10,[2]],
		"/admin/dashboard": [11,[2]],
		"/admin/educator": [12,[2]],
		"/admin/educator/resources": [13,[2]],
		"/admin/educator/resources/[slug]": [14,[2]],
		"/admin/educator/results": [15,[2]],
		"/admin/educator/results/ResultsTable": [16,[2]],
		"/admin/educator/student-results/[id]": [17,[2]],
		"/admin/educator/student-results/[id]/export": [18],
		"/admin/login": [19,[2]],
		"/admin/profile": [20,[2]],
		"/admin/roster": [21,[2]],
		"/admin/start-assessment": [22,[2]],
		"/admin/users": [23,[2]],
		"/admin/users/[id]": [24,[2]],
		"/exam-plan/[id]": [25,[3]],
		"/rapid-visual-processing/[id]": [26,[4]],
		"/student-game": [27,[5]],
		"/test-assets": [28,[6]],
		"/users": [29]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';